@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --background-color: transparent;
  --base-font-size: 12px;
  --base-font-weight: 400;
  --font-stack: "Inter", sans-serif;
  --heading-font-size: 14px;
  --edge-statistics-heading-font-size: 20px;
  --heading-font-weight: 700;
  --button-border-radius: 40px;
  --heading-sidebar-border-radius: 16px;
  --card-border-radius: 16px;
  --container-border-radius: 16px;
  --custom-header-font-size: 20px;
  --custom-header-font-weight: 700;
  --gap: 16px;
  --icon-font-size: 20px;
  --placeholder-color: rgb(145, 144, 144);
  --primary-color: rgba(27, 120, 229, 1);
  --white-color: #fff;
  --black-color: #000;
  --base-padding: 20px;
  --input-border-radius: 8px;
  --light-green-color: #35ab55;
  --light-green-shade: #90eda9;
  --light-yellow-color: #f7bd01;
  --light-red-color: #ff0000;
  --light-red-shade: #f68484;
  --button-font-weight: 600;
  --selected-border-radius: 8px;
  --container-backdrop-filter: blur(100px);
  --chart-header-font-size: 16px;
  user-select: none;
}

.light {
  --font-color: #000;
  --line-color: rgba(0, 0, 0, 0.35);
  --container-background-color: rgba(255, 255, 255, 0.46);
  --selected-nav-bg-color: rgb(255, 255, 255);
  --container-border: 1px solid rgba(255, 255, 255, 0.15);
  --background: url("../../assets/lightBack.png") no-repeat center center;
  --background-size: cover;
  --edge-statistics-card-bg-color: #dbece6;
  --table-bottom-border: 1px solid rgba(197, 197, 197, 1);
  --edge-statistics-utilization-text-color: rgba(0, 0, 0, 0.5);
  --line-chart-fill-color: #afd1f8;
  --line-chart-stroke-color: #3091ff;
  --table-header-color: #fbfdfd;
  --dialog-bg: #e5e9e9;
  --dialog-color: #e5e9e9;
  --tooltip-background-color: #f7f7f7;
  --cartesian-grid-stoke-color: #c5c3c3;
  --placeholder-color: #b9b6b6;
  --loader-background-color: #e0e0e0;
  --loader-foreground-color: #f7f7f7;
  --date-picker-color: invert(0) brightness(2);
}
.dark {
  --font-color: #fff;
  --line-color: rgba(255, 255, 255, 0.35);
  --container-background-color: rgba(255, 255, 255, 0.06);
  --container-border: 1px solid rgba(255, 255, 255, 0.15);
  --background: url("../../assets/darkBack.png") no-repeat center center;
  --background-size: cover;
  --selected-nav-bg-color: rgba(255, 255, 255, 0.23);
  --edge-statistics-card-bg-color: rgba(53, 186, 98, 0.06);
  --table-bottom-border: 1px solid rgba(74, 74, 74, 1);
  --edge-statistics-utilization-text-color: rgba(255, 255, 255, 0.5);
  --line-chart-fill-color: #0a213b;
  --line-chart-stroke-color: #0465d3;
  --table-header-color: #262c2c;
  --dialog-bg: #131414;
  --dialog-color: #131414;
  --tooltip-background-color: rgba(0, 0, 0, 0.9);
  --cartesian-grid-stoke-color: #616060;
  --placeholder-color: #474747;
  --loader-background-color: #2c2c2c;
  --loader-foreground-color: #3c3c3c;
  --date-picker-color: invert(1) brightness(2);
}
.dark select > option {
  color: var(--font-color);
  background-color: grey;
}
.dark .leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%) !important;
}

.container-style {
  background-color: var(--container-background-color);
  border: var(--container-border);
  backdrop-filter: blur(100px);
  border-radius: var(--container-border-radius);
}
.input-style {
  display: flex;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
  width: 100%;
}
.headerInput {
  margin: auto;
}
.input-style input {
  color: var(--font-color);
  outline: none;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: transparent;
}

.input-style select {
  width: 100%;
  color: var(--font-color);
  outline: none;
  padding: 10px;
  border: none;
  background-color: transparent;
}
.input-style input::placeholder {
  color: var(--placeholder-color);
}
.input-style {
  position: relative;
}
.input-style input[type="date"]::-webkit-calendar-picker-indicator,
.input-style input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 5px;
  top: 50%;
  z-index: inherit;
  transform: translateY(-50%);
  cursor: pointer;
  filter: var(--date-picker-color);
}
.customNavigator {
  height: 100%;
  width: 33vh;
}
.customTrendNavigator {
  height: 100%;
  width: 33vh;
}
.selected-nav {
  background: var(--selected-nav-bg-color);
  border-radius: var(--heading-sidebar-border-radius);
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(100px);
}
.selected {
  background: var(--selected-nav-bg-color);
  border-radius: var(--selected-border-radius);
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(100px);
}
.tooltipStyle {
  background-color: var(--dialog-bg) !important;
  border: var(--container-border) !important;
  border-radius: var(--container-border-radius) !important;
  color: var(--font-color) !important;
  z-index: 10000 !important;
}
.filled-btn-style.disabled {
  cursor: not-allowed;
}
.outline-btn-style.disabled {
  cursor: not-allowed;
}
.commonTableStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
}

.commonTableStyle table {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
}

.commonTableStyle thead {
  height: 5%;
  position: sticky;
  text-align: left;
  font-size: var(--heading-font-size);
  font-weight: var(--heading-font-weight);
  color: var(--font-color);
}

.commonTableStyle tbody {
  height: 95%;
  overflow-y: auto;
}

.commonTableStyle tbody tr {
  height: auto;
  min-height: 40px;
  cursor: pointer;
}
.commonTableStyle tbody tr:hover {
  height: auto;
  min-height: 40px;
  background-color: var(--container-background-color);
  cursor: pointer;
}

.commonTableStyle th {
  padding: 20px;
  text-align: left;
  border-bottom: var(--table-bottom-border);
}
.commonTableStyle td {
  padding: 20px;
  text-align: left;
}
.commonTableStyle tbody th {
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
}

.filled-btn-style {
  background-color: var(--primary-color);
  border-radius: var(--button-border-radius);
  color: #ffffff;
  font-size: var(--base-font-size);
  font-weight: var(--button-font-weight);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}
.filled-btn-style:hover {
  background-color: rgb(0, 90, 192);
}
.filled-btn-style:active {
  outline: none;
  border: none;
}
.outline-btn-style {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  border-radius: var(--button-border-radius);
  color: var(--primary-color);
  font-size: var(--base-font-size);
  font-weight: var(--button-font-weight);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}
.outline-btn-style:hover {
  border: 1px solid rgb(114, 174, 243);
}
.custom-dialog-style {
  background-color: var(--dialog-bg);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
  padding: 30px;
  width: 25%;
  margin: auto;
}
.custom-tabs {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  background-color: var(--container-background-color);
  border-radius: var(--container-border-radius);
}
.canvasTabs {
  margin-top: 5px;
}
.custom-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: transparent;
  color: var(--font-color);
  cursor: pointer;
}
.custom-tab-selected {
  display: flex;
  padding: 10px;
  font-weight: var(--custom-header-font-weight);
  color: var(--white-color);
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: var(--card-border-radius);
}

.custom-input-label {
  font-size: var(--base-font-size);
  font-weight: var(--custom-header-font-weight);
}
*::-webkit-scrollbar {
  display: none;
}
button {
  border: none;
}
body {
  color: var(--font-color);
  font-family: var(--font-stack);
  font-size: var(--base-font-size);
  overflow: hidden;
  height: 100%;
  font-weight: var(--base-font-weight);
  background: var(--background);
  background-color: transparent;
  background-size: var(--background-size);
  width: 100vw;
  height: 100vh;
}
.version {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 4000;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 10px;
}
.App {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  gap: 20px;
}
.content {
  display: flex;
  height: 86vh;
  /* width: 99.8vw; */
  gap: var(--gap);
}

.headerContent {
  display: flex;
  height: 7vh;
}

.AppRoutes {
  flex: 1;
  padding-left: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 2000;
  pointer-events: auto;
}

.itemSelected {
  background-color: var(--settings-selected-color);
  border-radius: var(--button-border-radius);
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
li {
  list-style: none;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: var(--font-color);
}
.link.settings {
  margin: auto;
  width: 100%;
  align-items: center;
  height: fit-content;
}
.edgeLink {
  text-decoration: none;
  color: var(--font-color);
  margin: 0px;
  padding: 0px;
  margin-top: auto;
  margin-bottom: auto;
}

.css-j204z7-MuiFormControlLabel-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.css-1jaw3da {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.css-8coetn {
  padding: 5px !important;
  text-align: left !important;
  color: var(--black-40, #1c1c1c66) !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  z-index: 0 !important;
}

/* input:checked {
  accent-color: var(--background-color-violet);
  color: var(--white-color);
} */

.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  min-height: 29px !important;
  margin-top: 3px !important;
  border-radius: var(--button-border-radius) !important;
}
.css-f7pxvb-MuiSwitch-root .MuiSwitch-track {
  background-color: var(--light-heading-color) !important;
}

/* Overriden Multiselect CSS */

.css-13cymwt-control {
  width: 70%;
  outline: none !important;
  width: 90% !important;
  margin: auto !important;
  border: none !important;
  background-color: var(--input-background-color) !important;
  border-radius: var(--button-border-radius) !important;
}
.css-3w2yfm-ValueContainer {
  width: 90% !important;
  display: flex !important;
  flex-direction: column !important;
}
.css-t3ipsp-control {
  outline: none !important;
  border: none !important;
  width: 90% !important;
  margin: auto !important;
  background-color: var(--input-background-color) !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.css-b62m3t-container {
  margin-bottom: 10px;
}

.common-btn {
  background-color: var(--heading-details-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
  color: var(--light-font-color);
  border-radius: 8px;
  font-weight: 600;
  width: fit-content;
  margin: auto;
  margin-top: 30px;
}
.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  background-color: white !important;
}

.common-rename-input {
  display: flex;
  min-width: fit-content;
  background-color: var(--background-color-violet);
  color: var(--light-font-color);
  text-align: center;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  font-size: 15px;
  font-weight: 500;
  border-radius: var(--button-border-radius);
  border: none;
}
.common-rename-input::placeholder {
  color: var(--light-font-color);
  opacity: 70%;
}
.css-y8ay40-MuiTableCell-root {
  background-color: var(--table-header-color) !important;
  font-size: var(--heading-font-size) !important;
  font-weight: var(--heading-font-weight) !important;
  /* border: none !important; */
  border-bottom: var(--container-border) !important;
}

.css-12t20bl-MuiSpeedDial-root .MuiSpeedDial-fab {
  background-color: var(--heading-details-color) !important;
}

.css-1byvl2s-MuiSpeedDial-root .MuiSpeedDial-fab {
  border-radius: 10px;
  background-color: white !important;
  box-shadow: none;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase .MuiSwitch-input {
  left: -100%;
  width: 300%;
  cursor: default;
}

.red {
  color: var(--light-red-color);
  text-shadow: none;
  font-weight: 600;
}
.yellow {
  color: rgb(255, 187, 0);
  font-weight: 600;
}
.green {
  color: green;
  font-weight: 600;
}

/* MUI TABLE STYLES */

.css-jtlhu6-MuiTablePagination-root:last-child {
  color: var(--font-color) !important;
}
.css-1ps6pg7-MuiPaper-root {
  background-color: var(--container-background-color) !important;
  border-radius: var(--container-border-radius) !important;
  border: var(--container-border) !important;
  backdrop-filter: blur(100px) !important;
  color: var(--font-color) !important;
  height: 100%;
  overflow: scroll;
  box-shadow: none !important;
}
.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  color: var(--font-color) !important;
}
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--font-color) !important;
}
.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  color: var(--font-color) !important;
}
.css-apqrd9-MuiTableBody-root {
  height: 20% !important;
}
.css-i4bv87-MuiSvgIcon-root {
  color: var(--font-color) !important;
}

.css-15wwp11-MuiTableHead-root {
  height: 50px !important;
  margin: auto !important;
  gap: 16px !important;
  justify-content: center !important;
  border-radius: 16px !important;
}
.css-rorn0c-MuiTableContainer-root {
  max-height: 92% !important;
}
.css-y8ay40-MuiTableCell-root {
  padding: 5px !important;
  text-align: left !important;
  line-height: 20px !important;
  z-index: 0 !important;
  padding: 16px !important;
}
.css-1ex1afd-MuiTableCell-root {
  text-align: left !important;
  font-weight: var(--base-font-weight) !important;
  font-size: var(--base-font-size) !important;
  border-bottom: var(--container-border) !important;
}
.css-dwuj3p-MuiTableCell-root {
  text-align: left !important;
  border-bottom: var(--container-border) !important;
}
.css-1yhpg23-MuiTableCell-root {
  align-items: center !important;
  justify-content: center !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  font-size: var(--base-font-size) !important;
  font-weight: var(--base-font-weight) !important;
  text-align: left !important;
  border-bottom: var(--container-border) !important;
}
.css-g9ktl7-MuiTableCell-root {
  text-align: left !important;
  border-bottom: var(--container-border) !important;
  padding: 0px !important;
}
/* Full Calender Overriden Styles */

.fc {
  width: 98.5% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: var(--container-background-color) !important;
  margin-top: 10px !important;
  border: none !important;
  border-radius: var(--container-border-radius) !important;
  backdrop-filter: var(--container-backdrop-filter) !important;
  height: 90% !important;
  overflow: scroll !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.fc .fc-toolbar.fc-header-toolbar {
  display: none !important;
}
.fc .fc-view-harness {
  height: 100% !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  border-radius: var(--container-border-radius) !important;
}
.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}
.fc-theme-standard .fc-scrollgrid thead {
  color: var(--font-color) !important;
  border-bottom: 1px solid var(--font-color) !important;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: none !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  border-right: 1px solid var(--font-color) !important;
  color: var(--font-color) !important;
}
.fc .fc-col-header-cell-cushion {
  color: var(--font-color) !important;
  font-size: var(--heading-font-size) !important;
  font-weight: var(--heading-font-weight) !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

/* Overriden Phone Field CSS */
.react-international-phone-input-container {
  display: flex;
}
.react-international-phone-input-container .react-international-phone-input {
  display: flex !important;
  margin: auto !important;
  border: none !important;
  width: 100% !important;
  color: var(--font-color) !important;
  background-color: transparent !important;
}
.react-international-phone-country-selector-button {
  background-color: transparent !important;
  border: none !important;
}
.react-international-phone-country-selector-dropdown {
  z-index: 2000 !important;
}
/* Force autofill background color */
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: var(--font-color) !important;
  transition:
    background-color 5000s ease-in-out 0s,
    -webkit-text-fill-color 5000s ease-in-out 0s;
}

/* Extra Table Style for Map */

.noTableData {
  text-align: center !important;
}
.alarmview {
  display: flex;
  position: relative;
  width: 100% !important;
  height: 100%;
  margin-top: 10px;
  overflow: scroll;
}
/*Custom Styles for Date-Time Picker  */

.rdt {
  display: flex;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
  width: 100%;
}
.rdtPicker {
  z-index: 9999 !important;
  position: absolute !important;
  top: 100%; /* Aligns directly below the input */
  left: 0;
}
.rdt input {
  color: var(--font-color);
  outline: none;
  width: 100%;
  padding: 12px;
  border: none;
  background-color: transparent;
}
.rdtPicker {
  background-color: #2e3d40 !important;
  border: var(--container-border) !important;
  border-radius: var(--input-border-radius);
}
/* .rdtPicker:hover {
  background-color: var(--background-color) !important;
} */
.rdtPicker th:hover {
  background-color: var(--container-background-color) !important;
}
.rdtPicker td:hover {
  background-color: var(--container-background-color) !important;
}
.rdtBtn:hover {
  background-color: var(--container-background-color) !important;
}
/* Overriden Styles for Reordering */
.css-19s3t1h {
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  padding: 0px;
  gap: 2px;
}

.yamada-ui-reorder,
.yamada-ui-reorder-item {
  transition: none !important;
  animation: none !important;
}
/* .layout {
  background-size: 60px 60px;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
} */

.templateTabs {
  display: flex;
  max-width: 80%;
  overflow: scroll;
}
.tooltipStyle {
  outline: none !important;
  border: none !important;
}
*[data-tooltip-id] {
  outline: none !important;
}
