.threePointDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  height: 33vh;
  overflow: scroll;
  width: 20%;
  position: absolute;
  top: 30px;
  right: 20px;
  background-color: var(--dialog-bg);
  backdrop-filter: var(--container-backdrop-filter);
  border-radius: var(--container-border-radius);
  border: var(--container-border);
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.threePointDetailsHeading {
  display: flex;
  width: 100%;
  height: 15%;
  align-items: center;
  font-size: 16px;
  overflow: scroll;
  font-weight: var(--heading-font-weight);
}
.treePointDetailsContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  gap: 10px;
}
.threePointValue {
  display: flex;
  height: 40%;
  align-items: center;
  font-size: 30px;
  font-weight: var(--heading-font-weight);
}
.threePointInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
}
.threePointStatus {
  display: flex;
}
.threePointInfoBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
}
.twinChartLabel {
  position: absolute;
  width: fit-content;
  font-size: 16px;
  font-weight: var(--custom-header-font-weight);
  margin-left: 30px;
  top: 50px;
  z-index: 999;
}
.gldfBtnContainer {
  display: flex;
  width: 95%;
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
  height: 7%;
  gap: 5px;
  align-items: center;
}
.threeHomeBtn {
  background-color: var(--container-background-color);
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.threeHomeBtn:hover {
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
}
.ahuBtn {
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background-color: var(--container-background-color);
  cursor: pointer;
  top: 50px;
  color: var(--font-color);
}
.ahuBtn:hover {
  background-color: var(--container-background-color);
  backdrop-filter: var(--container-backdrop-filter);
}
