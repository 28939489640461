.customFormInput {
  display: flex;
  width: 90%;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  flex-direction: column;
}
.customFormLabel {
  text-align: left;
  font-weight: 600;
  margin-bottom: 10px;
}
.customFormInputField {
  display: flex;
  width: 100%;
  align-self: center;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: var(--input-border-radius);
  color: var(--font-color);
  width: 100%;
}
.customFormInputField .customFormInputBox {
  padding: 10px;
}
.customFormInputField input {
  color: var(--font-color);
  outline: none;
  width: 100%;
  border: none;
  background-color: transparent;
}
.customFormInputBox.disabled {
  cursor: not-allowed;
}
.customFormInputField input::placeholder {
  color: rgb(145, 144, 144);
}
.customFormInputField.checkbox {
  width: fit-content !important;
  margin: 10px;
  align-self: flex-start;
  position: relative;
  left: 200px;
  bottom: 35px;
  border: none;
}
.customFormInputBox {
  display: flex;
  border: none;
  width: 100%;
  margin: auto;
  align-self: center;
  outline: none;
  max-height: 100px;
  overflow: scroll;
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  color: var(--font-color);
  background-color: transparent;
  padding: 13px;
}
.customFormInputField.focused {
  border: 1px solid var(--primary-color);
}

.customFormInputBox::placeholder {
  color: var(--font-color);
}

.required {
  color: var(--light-red-color);
}
.customInputField {
  display: flex;
}
.passwordToggle {
  width: 10%;
  margin: auto;
  margin-right: 8px;
}
/* Multiselect CSS */
.selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.selected-item {
  background-color: var(--container-background-color);
  padding: 3px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.remove-btn {
  background: none;
  border: none;
  color: var(--light-red-color);
  margin-left: 5px;
  cursor: pointer;
  font-size: 15px;
}

.dropdown {
  position: absolute;
  background: var(--dialog-bg);
  backdrop-filter: var(--container-backdrop-filter);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
  width: fit-content;
  height: 30%;
  z-index: 10;
  padding: 5px;
}

.search-input {
  margin: auto;
}

.dropdown-options {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 80%;
  height: 75%;
  overflow-y: auto;
}

.dropdown-option {
  padding: 5px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: var(--container-background-color);
  border-radius: 5px;
}
.clear-all-btn {
  display: flex;
  width: 30px;
  margin-right: 3px;
  height: 100%;
  border-left: 1px solid var(--font-color);
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  color: var(--font-color);
  font-size: 30px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
