.leaflet-control-attribution .leaflet-attribution-flag,
.leaflet-control-attribution a[href*="leaflet"],
.leaflet-control-attribution span[aria-hidden="true"] {
  display: none !important;
}

.leaflet-control-attribution {
  border-top-right-radius: 5px;
}

.leaflet-container {
  flex: none !important;
  height: 43vh !important;
  border-radius: var(--container-border-radius);
  overflow: hidden;
}

.mapview {
  display: flex;
  /* justify-content: flex-end; */
  flex-direction: column;
  height: 100%;
}
.custom-marker {
  position: relative;
  width: 24px;
  height: 24px;
  background-color: var(--font-color);
  border-radius: 50%;
  border: var(--container-border);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.custom-marker.red {
  background-color: var(--light-green-color);
}
.custom-marker::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: var(--dialog-color);
  border-radius: 50%;
}
.custom-marker.red::after {
  border-color: var(--light-green-color) transparent transparent transparent;
}
.custom-marker::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: var(--font-color) transparent transparent transparent;
}
.locate-btn {
  position: absolute;
  top: 25px;
  right: 10px;
  z-index: 1000;
  padding: 5px 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.locate-btn:hover {
  background-color: #0056b3;
}

.location-home-btn {
  position: absolute;
  top: 65px;
  right: 10px;
  z-index: 1000;
  padding: 5px 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.location-home-btn:hover {
  background-color: #0056b3;
}
.siteSelected {
  background-color: var(--container-background-color);
  padding: 5px;
  border-radius: var(--input-border-radius);
  backdrop-filter: var(--container-backdrop-filter);
}
.siteName {
  padding: 5px;
  cursor: pointer;
}
