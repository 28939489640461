.lineChart {
  width: 100%;
}
.recharts-wrapper {
  width: 100% !important;
  height: 100% !important;
  /* margin-right: 40px; */
  margin-top: auto;
  margin-bottom: auto;
}
.chartLabel {
  display: flex;
  /* width: 90%; */
  height: 4vh;
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  text-align: left;
  justify-content: left;
  align-items: center;
  /* margin-top: 10px; */
  margin-left: 10px;
  margin-right: auto;
  margin-bottom: 10px;
}

.lineLabel {
  padding: 10px;
  margin-top: -10px;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}
.line {
  width: 100%;
  height: 70px;
}
.noData {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  z-index: 999;
  white-space: nowrap;
  height: 100%;
  margin-top: auto;
  color: var(--disabled-color);
}
.noData.template {
  min-height: 100px;
}
.timeWidget {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.timeWidget span {
  font-weight: var(--heading-font-weight);
  justify-content: center;
}
