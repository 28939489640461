.numericWidgetTitle {
  display: flex;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 5.5px;
  font-weight: 700;
  font-size: 16px;
}
/* @import url("https://fonts.cdnfonts.com/css/library-3-am"); */
.numericValue {
  width: 85%;
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: left;
  /* font-family: "LIBRARY 3 AM", sans-serif; */
  font-size: 40px;
  margin: auto;
  font-weight: var(--heading-font-weight);
  color: var(--font-color);
}
.numericValue.red {
  color: var(--light-red-color);
}
.numericValue.yellow {
  color: var(--light-yellow-color);
}
.numericWidget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.switchWidget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  max-height: fit-content;
  min-height: fit-content;
  overflow: hidden;
}
.switchWidgetTitle {
  font-weight: 700;
  margin-top: 5px;
}
.watchlistWidget {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.watchlistContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  height: 90%;
  margin-top: auto;
  margin-bottom: auto;
  overflow: scroll;
}
.watchlistCard {
  display: flex;
  padding: 10px;
  background-color: var(--container-background-color);
  border: var(--container-border);
  border-radius: 10px;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.watchlistCard:hover {
  backdrop-filter: var(--container-backdrop-filter);
}
.watchlistLabel {
  width: 60%;
  text-align: left;
  overflow: scroll;
}
.watchlistValue {
  width: 40%;
  overflow: scroll;
  text-align: right;
}
.watchlistContent-accordion {
  max-height: 50vh;
  overflow: scroll;
}
.watchlistWidget .customFormInput {
  width: 99%;
}
.kpiConsumptionPercent {
  display: flex;
  padding: 5px;
  position: absolute;
  top: 15%;
  font-size: 15px;
  border-radius: 20px;
  font-weight: 600;
  right: 2%;
}
.kpiCardContent {
  display: flex;
  position: relative;
  width: 50%;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  height: 50%;
  margin-left: 30px;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.kpiCardContent span {
  font-size: 40px;
  font-weight: var(--heading-font-weight);
}
.kpiBaselineContent {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  /* color: #636363; */
  height: fit-content;
  align-items: center;
  margin-top: auto;
  justify-content: flex-end;
  margin-right: 20px;
}
.kpiSmallCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.kpiHeading {
  display: flex;
  width: 90%;
  margin: auto;
  align-items: center;
  height: 3.5vh;
  justify-content: space-between;
}
.kpiHeading span {
  font-size: var(--custom-header-font-size);
  font-weight: var(--heading-font-weight);
}
.kpiSubHeading {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
}
.kpiHeading.kpiSmallCard {
  height: 3vh;
  width: 85%;
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 0px;
  margin-top: 3px;
  margin-right: auto;
}
.kpiSubHeading.kpiSmallCard {
  width: 85%;
  margin-left: 30px;
  gap: 10px;
  margin-bottom: 0px;
  margin-top: 3px;
  align-items: center;
  margin-right: auto;
  height: 20%;
}
.kpiSmallCardSwitch {
  height: 20px;
  align-items: center;
}
