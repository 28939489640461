.dateFilterInputGroup {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 95%;
  height: 100%;
  margin-top: 7px;
  margin-left: auto;
  margin-right: auto;
}
